body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-wrapper {
  padding: 2em;
}

.loading-alert {
  text-align: center;
}

/* Tables */
.td-currency, .td-right {
  text-align: right;
}

.td-currency {
  min-width: 12em;
  font-family: 'Courier New', Courier, monospace;
}

.table-sm th.td-right,
.table-sm td.td-currency {
  padding-right: 3em;
}

.td-maxquarter {
  max-width: 25em;
}

.td-total {
  font-weight: bold;
}

tr.summary-row {
  font-weight: 900;
}

.td-size-xs {
  width: 5em;
}

.td-size-s {
  width: 10em;
}
/* Others */

.subtitle {
  display: block;
  font-size: 66%;
  font-weight: 100;
}

.add-margin {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.filterbox button {
  border-radius: 1em;
  padding-top: 0em;
  padding-bottom: 0em;
}

.modal-content {
  padding: 1em;
}

.form-array-entry {
  margin: 0.5em;
  padding: 0.5em;
  background-color:lightgray;
  border-radius: 0.3em;
}

.form-error {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

.money-positive {
  color:darkgreen;
}

.money-negative {
  color: red;
}

.filter-card {
  margin-left: 0.2em;
  margin-right: 0.2em;
  padding: 0.2em;
  background-color: white;
  border-radius: 0.3em;
}

.btn-link {
  padding-left: 0.5em;
}

.btn-link svg {
  vertical-align: baseline;
}

.subtable-indent {
  margin-left: 2.5em;
}

.linkbutton {
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.card-big-amount {
  font-size: 200%;
}

.inline-link-list::after {
  content: ", "
}

.inline-link-list:last-child::after {
  content: ""
}
